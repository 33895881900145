@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap');
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td{background: transparent;border: 0;margin: 0;padding: 0;vertical-align: baseline} a{text-decoration:none}
/*GENERALES*/
*, *:after ,*:before{
  box-sizing:border-box;
}
.animado, a{
  transition: all .3s ease-out;
}
.wrap{
  width: 90%;
  margin: auto;
}
html,body{
  width: 100%;
  height: 100%;
}
body{
  font-family: 'Nunito', sans-serif;
  font-size: .8rem;
  font-weight: 200;
}
h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.2;
}
.material-icons {
  font-family: 'Material Icons Outline';
}
.keen-slider,.navigation-wrapper{
  height: 100%;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

